import React, { useEffect, useState, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import type { AdminReading, Flashcard, RawWordBreakdown, SentenceResponseRaw } from '../../types';
import { getFlashcard } from '../../actions';
import FlashcardCard from '../FlashcardCard';
import type { MouseEvent } from 'react';

const ReadingDetails: React.FC = () => {
  /* Hooks */

  const [reading, setReading] = useState<AdminReading | undefined>(undefined);
  const [gradeValue, setGradeValue] = useState<string>('');
  const [vocabValue, setVocabValue] = useState<string>('');
  const [vocabHiraganaValue, setVocabHiraganaValue] = useState<string>('');
  const [vocabMeaningValue, setVocabMeaningValue] = useState<string>('');
  const [sentenceValue, setSentenceValue] = useState<string>('');
  const [sentenceHiraganaValue, setSentenceHiraganaValue] = useState<string>('');
  const [sentenceMeaningValue, setSentenceMeaningValue] = useState<string>('');
  const [audioSentenceValue, setAudioSentenceValue] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [flashcard, setFlashcard] = useState<Flashcard | undefined>(undefined);
  const [prevReading, setPrevReading] = useState<AdminReading | undefined>(undefined);
  const [nextReading, setNextReading] = useState<AdminReading | undefined>(undefined);
  const [readingIndex, setReadingIndex] = useState<number | undefined>(undefined);
  const [isVocabAlreadyUsed, setIsVocabAlreadyUsed] = useState<boolean>(false);

  const { id: readingId } = useParams();

  const navigate = useNavigate();

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const readingQuery = useQuery({
    queryKey: ['admin', 'reading', readingId],
    queryFn: async () =>
      await axios
        .get(`/api/admin/readings/${readingId}`)
        .then((res) => res.data),
  });

  const readingsQuery = useQuery({
    queryKey: ['admin', 'readings'],
    queryFn: async () =>
      await axios
        .get(`/api/admin/readings?level=${reading?.level}`)
        .then((res) => res.data),
    enabled: !!reading
  });

  useEffect(() => {
    const sameVocabReadingIds = readingQuery?.data?.same_vocab_reading_ids;
    if (sameVocabReadingIds?.length && reading?.id && vocabValue) {
      const isVocabAlreadyUsedOnAnotherKanjiReading = !sameVocabReadingIds.includes(reading.id) || sameVocabReadingIds.length > 1;
      setIsVocabAlreadyUsed(isVocabAlreadyUsedOnAnotherKanjiReading);
    } else {
      setIsVocabAlreadyUsed(false);
    }
  }, [(readingQuery?.data?.same_vocab_reading_ids || []).join(','), reading?.id, vocabValue]);

  useEffect(() => {
    if (reading?.id && readingsQuery?.data?.readings) {
      const readings = readingsQuery.data.readings
      const index = readings.findIndex((_reading: AdminReading) => _reading.id === reading.id);
      setReadingIndex(index as number | undefined);
      setPrevReading(index > 0 ? readings[index - 1] as AdminReading : undefined);
      setNextReading(index < readings.length - 1 ? readings[index + 1] as AdminReading : undefined);
    }
  }, [reading?.id, !!readingsQuery?.data?.readings]);

  useEffect(() => {
    if (readingId) {
      void readingQuery.refetch();
    }
  }, [readingId]);

  useEffect(() => {
    playAudio();
  }, [readingId, audioRef.current?.src]);

  useEffect(() => {
    const _reading = readingQuery?.data?.reading;
    if (_reading) {
      setReading(_reading as AdminReading);
      setGradeValue((_reading.grade as string) || '');
      setVocabValue((_reading.vocab as string) || '');
      setVocabHiraganaValue((_reading.vocab_hiragana as string) || '');
      setVocabMeaningValue((_reading.vocab_meaning as string) || '');
      setSentenceValue((_reading.sentence as string) || '');
      setSentenceHiraganaValue((_reading.sentence_hiragana as string) || '');
      setSentenceMeaningValue((_reading.sentence_meaning as string) || '');
      setAudioSentenceValue((_reading.audio_sentence as string) || '');
      void (async () => {
        const { flashcard: _flashcard } = await getFlashcard(Number(readingId));
        setFlashcard(_flashcard);
      })()
    }
  }, [JSON.stringify(readingQuery?.data)]);

  // Use useEffect to add the event listener
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    JSON.stringify(reading),
    prevReading?.id,
    nextReading?.id,
    gradeValue,
    vocabValue,
    vocabHiraganaValue,
    vocabMeaningValue,
    sentenceValue,
    sentenceHiraganaValue,
    sentenceMeaningValue,
    audioSentenceValue,
  ]);

  /* Handlers */

  // Function to handle the key press event
  const handleKeyDown = (event: KeyboardEvent): void => {
    const activeElement = document.activeElement;
    if (
      activeElement instanceof HTMLInputElement ||
      activeElement instanceof HTMLTextAreaElement ||
      (activeElement && activeElement.getAttribute('contenteditable') === 'true')
    ) {
      // If the user is typing in an input or textarea, do nothing
      return;
    }

    if (event.key === 'ArrowRight' && nextReading?.id) {
      void (async () => {
        if (hasChanges()) {
          const result = confirm('Do you want to save?');
          if (result) {
            await handleSave();
          }
        }
        navigate(`/admin/readings/${nextReading.id}`);
      })();
    } else if (event.key === 'ArrowLeft' && prevReading?.id) {
      navigate(`/admin/readings/${prevReading.id}`);
    }
  };

  const handleGradeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setGradeValue(event.target.value);
  };

  const handleVocabChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setVocabValue(event.target.value);
  };

  const handleVocabHiraganaChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setVocabHiraganaValue(event.target.value);
  };

  const handleVocabMeaningChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setVocabMeaningValue(event.target.value);
  };

  const handleSentenceChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setSentenceValue(event.target.value);
  };

  const handleSentenceHiraganaChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setSentenceHiraganaValue(event.target.value);
  };

  const handleSentenceMeaningChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setSentenceMeaningValue(event.target.value);
  };

  const handleAudioSentenceChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setAudioSentenceValue(event.target.value);
  };

  const handleSave = async (): Promise<void> => {
    // Any additional state referenced
    // here has to be added to the dependencies
    // of the useEffect function that listens
    // for key presses.
    const data: Partial<AdminReading> = {
      grade: gradeValue,
      vocab: vocabValue,
      vocab_hiragana: vocabHiraganaValue,
      vocab_meaning: vocabMeaningValue,
      sentence: sentenceValue,
      sentence_hiragana: sentenceHiraganaValue,
      sentence_meaning: sentenceMeaningValue,
      audio_sentence: audioSentenceValue,
    };
    setIsUpdating(true);
    await axios.patch(
      `/api/admin/readings/${reading?.id}`,
      { reading: data },
      {
        headers: {
          'Content-Type': 'application/json', // Tells Rails it's a JSON request
          Accept: 'application/json', // Ensures Rails responds with JSON
        }
      }
    );
    await readingQuery.refetch();
    setIsUpdating(false);
  };

  const handleFetchSentenceClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    setIsUpdating(true);
    await axios.post(`/api/admin/readings/${reading?.id}/fetch_sentence`);
    await readingQuery.refetch();
    setIsUpdating(false);
  };

  const handleFetchImageClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    setIsUpdating(true);
    await axios.post(`/api/admin/readings/${reading?.id}/fetch_image`);
    await readingQuery.refetch();
    setIsUpdating(false);
  };

  const handleFetchAudioClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    setIsUpdating(true);
    await axios.post(`/api/admin/readings/${reading?.id}/fetch_audio`);
    await readingQuery.refetch();
    setIsUpdating(false);
  };

  /*
  const handleUploadImageClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    setIsUpdating(true);
    await axios.post(`/api/admin/readings/${reading?.id}/upload_image`);
    await readingQuery.refetch();
    setIsUpdating(false);
  };
  */

  const handleFetchVocabClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    setIsUpdating(true);
    await axios.post(`/api/admin/readings/${reading?.id}/fetch_vocab`);
    await readingQuery.refetch();
    setIsUpdating(false);
  };

  const handleApplyVocabClick = (wb: RawWordBreakdown): void => {
    setVocabValue(wb.word);
    setVocabHiraganaValue(wb.pronunciation ?? '');
    setVocabMeaningValue(wb.meaning);
  };

  const handleApplySentence = (): void => {
    if (!reading) {
      return;
    }
    const object: SentenceResponseRaw = JSON.parse(reading.sentence_response_raw);
    setSentenceValue(object.sentence ?? object.japanese ?? object.japanese_sentence ?? '');
    setSentenceMeaningValue(object.meaning ?? object.english_meaning ?? object.english ?? object.english_translation ?? object.englishMeaning ?? '');
    setSentenceHiraganaValue(JSON.stringify(object.breakdown ?? object.word_breakdown ?? object.wordBreakdown ?? ''));
  };

  const hasChanges = (): boolean => {
    if (!reading) {
      return false;
    }
    return gradeValue !== (reading.grade || '') ||
      vocabValue !== (reading.vocab || '') ||
      vocabHiraganaValue !== (reading.vocab_hiragana || '') ||
      vocabMeaningValue !== (reading.vocab_meaning || '') ||
      sentenceValue !== (reading.sentence || '') ||
      sentenceHiraganaValue !== (reading.sentence_hiragana || '') ||
      sentenceMeaningValue !== (reading.sentence_meaning || '') ||
      audioSentenceValue !== (reading.audio_sentence || '');
  };

  const playAudio = (): void => {
    if (audioRef.current) {
      void audioRef.current.play();
    }
  };

  if (readingQuery.isLoading || readingsQuery.isLoading || !reading) return 'Loading...';

  const sentenceResponse: undefined | SentenceResponseRaw = reading.sentence_response_raw && JSON.parse(reading.sentence_response_raw);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4">
          <p>
            <Link to={`/admin/readings/${prevReading?.id}`} className={`btn btn-outline-secondary btn-sm ${isUpdating || !prevReading?.id ? 'disabled' : ''}`}>Previous</Link>{' '}
            <Link to={`/admin/readings/${nextReading?.id}`} className={`btn btn-outline-secondary btn-sm ${isUpdating || !nextReading?.id ? 'disabled' : ''}`}>Next</Link>{' '}
            {readingIndex === undefined ? '' : readingIndex + 1} / {readingsQuery.data.readings.length}
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <h2>{reading.kanji} [{reading.reading}]</h2>
          <table className="table table-borderless">
            <tbody>
              <tr>
                <th>ID</th>
                <td>{reading.id}</td>
              </tr>
              <tr>
                <th>Level</th>
                <td>{reading.level}</td>
              </tr>
              <tr>
                <th>Grade</th>
                <td>
                  <select className="form-select" value={gradeValue} disabled={isUpdating} onChange={handleGradeChange}>
                    <option value="elementary">Elementary</option>
                    <option value="middle">Middle</option>
                    <option value="high">High</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>Vocab</th>
                <td>
                  <input type="text" className={`form-control ${isVocabAlreadyUsed ? 'is-invalid' : ''}`} id="readingVocab" disabled={isUpdating} value={vocabValue} onChange={handleVocabChange} />
                  {isVocabAlreadyUsed &&
                    <div id="validationReadingVocabFeedback" className="invalid-feedback">
                      This vocab is already in use.{' '}
                      {readingQuery.data.same_vocab_reading_ids.map((_id: number, index: number) => (
                        <>
                          <Link to={`/admin/readings/${_id}`}>{index + 1}</Link>{' '}
                        </>
                      ))}
                    </div>
                  }
                </td>
              </tr>
              <tr>
                <th>Vocab Hiragana</th>
                <td>
                  <input type="text" className="form-control" id="readingVocabHiragana" disabled={isUpdating} value={vocabHiraganaValue} onChange={handleVocabHiraganaChange} />
                </td>
              </tr>
              <tr>
                <th>Vocab Meaning</th>
                <td>
                  <input type="text" className="form-control" id="readingVocabHiragana" disabled={isUpdating} value={vocabMeaningValue} onChange={handleVocabMeaningChange} />
                </td>
              </tr>
              <tr>
                <th>Sentence</th>
                <td>
                  <textarea
                    id="readingSentence"
                    className="form-control"
                    disabled={isUpdating}
                    value={sentenceValue}
                    onChange={handleSentenceChange}
                    rows={5}
                  />
                </td>
              </tr>
              <tr>
                <th>Sentence Hiragana</th>
                <td>
                  <textarea
                    id="readingSentenceHiragana"
                    className="form-control"
                    disabled={isUpdating}
                    value={sentenceHiraganaValue}
                    onChange={handleSentenceHiraganaChange}
                    rows={3}
                  />
                </td>
              </tr>
              <tr>
                <th>Sentence Meaning</th>
                <td>
                  <textarea
                    id="readingSentenceMeaning"
                    className="form-control"
                    disabled={isUpdating}
                    value={sentenceMeaningValue}
                    onChange={handleSentenceMeaningChange}
                    rows={3}
                  />
                </td>
              </tr>
              <tr>
                <th>Audio Sentence</th>
                <td>
                  <textarea
                    id="readingAudioSentence"
                    className="form-control"
                    disabled={isUpdating}
                    value={audioSentenceValue}
                    onChange={handleAudioSentenceChange}
                    rows={5}
                  />
                  <p style={{ fontSize: '9px' }}><pre><code>{'<speak>\n<phoneme alphabet="x-amazon-yomigana" ph="ひろかず">浩一</phoneme>\n</speak>'}</code></pre></p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="col-md-4">
          <button type="button" className={`btn btn-primary ${isUpdating ? 'disabled' : ''}`} onClick={() => { void handleSave() }}>Save</button>
          <button type="button" className={`ms-2 btn btn-outline-secondary ${isUpdating ? 'disabled' : ''}`} onClick={(event) => { void handleFetchVocabClick(event) }}>Fetch vocab</button>
          <button type="button" className={`ms-2 btn btn-outline-secondary ${isUpdating ? 'disabled' : ''}`} onClick={(event) => { void handleFetchSentenceClick(event) }}>Fetch sentence</button>
          <button type="button" className={`ms-2 btn btn-outline-secondary ${isUpdating ? 'disabled' : ''}`} onClick={(event) => { void handleFetchImageClick(event) }}>Fetch image</button>
          <button type="button" className={`ms-2 btn btn-outline-secondary ${isUpdating ? 'disabled' : ''}`} onClick={(event) => { void handleFetchAudioClick(event) }}>Fetch audio</button>

          <hr />

          <h3>Vocab response raw</h3>
          <table className="table">
            <tbody>
            {reading.vocab_response_raw && JSON.parse(reading.vocab_response_raw)?.words?.map((wb: RawWordBreakdown) => (
              <tr key={wb.word}>
                <td style={{ whiteSpace: 'nowrap' }}>{wb.word}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{wb.pronunciation}</td>
                <td>{wb.meaning}</td>
                <td><button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => { handleApplyVocabClick(wb) }}>Apply</button></td>
              </tr>
            ))}
            </tbody>
          </table>

          <h3>Sentence response raw</h3>

          {sentenceResponse && (
            <>
              <p><button type="button" className="btn btn-outline-secondary btn-sm" onClick={handleApplySentence}>Apply</button></p>
              <p>{sentenceResponse.sentence ?? sentenceResponse.japanese ?? sentenceResponse.japanese_sentence}</p>
              <p>{sentenceResponse.meaning ?? sentenceResponse.english_meaning ?? sentenceResponse.english ?? sentenceResponse.english_translation ?? sentenceResponse.englishMeaning}</p>
              <p>{JSON.stringify(sentenceResponse.breakdown ?? sentenceResponse.word_breakdown ?? sentenceResponse.wordBreakdown)}</p>
            </>
          )}

          {/*
          <h3>DALLE raw image</h3>
          {!!reading.image_tmp && (
            <>
              <p><button type="button" className="btn btn-outline-secondary btn-sm" onClick={(event) => { void handleUploadImageClick(event) }}>Upload</button></p>
              <div className="mt-4 card">
                <div className="card-body">
                  <img width="100%" src={reading.image_tmp} />
                </div>
              </div>
            </>
          )}
          */}

          <h3>Audio</h3>
          {!!reading.audio_url && (
            <div>
              <audio ref={audioRef} src={reading.audio_url} />
              <button onClick={playAudio}>Play</button>
            </div>
          )}

          <h3 className="mt-4">Image</h3>
          <form
            action={`/api/admin/readings/${reading?.id}`}
            method="POST"
            encType="multipart/form-data"
          >
            <input type="hidden" name="_method" value="patch" />
            <input type="file" id="reading_image" name="reading[image_file]" accept="image/*" />
            <button className="btn btn-outline-secondary btn-sm" type="submit">Upload</button>
          </form>

          {reading.image_url && (
            <div className="mt-4 card">
              <div className="card-body">
                <img width="100%" src={reading.image_url} />
              </div>
            </div>
          )}
        </div>

        <div className="col-md-4">
          <h3>Flashcard preview</h3>
          <div className="mt-4 card">
            <div className="card-body">
              {!!flashcard && <FlashcardCard flashcard={flashcard} flipped={false} loading={false} onFlip={() => {}} onNext={() => {}} />}
            </div>
          </div>

          <div className="mt-4 card">
            <div className="card-body">
              {!!flashcard && <FlashcardCard flashcard={flashcard} flipped={true} loading={false} onFlip={() => {}} onNext={() => {}} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadingDetails;
