import React from 'react';

interface Props {
  label?: string
  disabled?: boolean
  large?: boolean
  icon?: string
}

const SubscribeButton: React.FC<Props> = ({ label, disabled, large, icon }) => {
  return (
    <form action="/checkout" method="post">
      <button type="submit" className={`btn btn-primary ${large ? 'btn-lg' : ''}`} disabled={disabled}>
        {!!icon && <><i className={`bi bi-${icon}`}></i>{' '}</>}
        {label ?? 'Subscribe'}
      </button>
    </form>
  );
};

export default SubscribeButton;
