import React, { useEffect, useState } from 'react';
import axios from 'axios';
import type { User } from '../../types';
import { Link } from 'react-router-dom';

const UsersIndex: React.FC = () => {
  /* Hooks */

  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    void (async () => {
      const res = await axios.get('/api/admin/users');
      setUsers(res.data.users as User[]);
    })();
  }, []);

  /* Handlers */

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2">
        </div>
      </div>

      <br/>
      <p>Showing {users.length} users</p>

      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Created Date</th>
            <th>Email</th>
            <th>Name</th>
            <th>Provider</th>
            <th>Timezone</th>
            <th>Level</th>
            <th>Flashcard Count</th>
            <th>Subscription</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users.map((user: User) =>
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.created_at.toString()}</td>
              <td>{user.email}</td>
              <td>{user.name}</td>
              <td>{user.provider}</td>
              <td>{user.timezone}</td>
              <td>{user.level}</td>
              <td>{user.flashcard_count}</td>
              <td>{user.subscription_status}</td>
              <td>
                <Link to={`/admin/users/${user.id}`} className="btn btn-outline-secondary btn-sm" target="_blank">View</Link>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UsersIndex;
