import React from 'react';
import type { MouseEvent } from 'react';
import type { Flashcard } from '../types';
import { formattedSentence, formatBreakdown, pluralize } from '../common';
import Markdown from 'react-markdown'

interface Props {
  flashcard: Flashcard
  flipped: boolean
  loading: boolean
  onFlip: () => void
  onNext: (grade: number) => void
  hideButtons?: boolean
  last?: boolean
  audioLoaded?: boolean
  audioPlaying?: boolean
  playAudio?: () => void
}

const FlashcardCard: React.FC<Props> = ({
  flashcard,
  flipped,
  loading,
  onFlip,
  onNext,
  hideButtons,
  last,
  audioLoaded,
  audioPlaying,
  playAudio,
}) => {
  const handleFail = (): void => {
    onNext(0);
  };

  const handleHardSuccess = (): void => {
    onNext(3);
  };

  const handleEasySuccess = (): void => {
    onNext(5);
  };

  const handleClickPlayAudio = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();
    if (playAudio) {
      playAudio();
    }
  };

  const copySentenceToClipboard = (): void => {
    void navigator.clipboard.writeText(flashcard?.sentence);
  };

  if (loading) {
    // return null;
  }

  if (!flashcard?.sentence || !flashcard.sentence_hiragana || !flashcard.sentence_meaning) {
    return 'Invalid card';
  }

  const isCardNew = flashcard?.flashcard?.attempts === 0;

  const hasAudio = flipped && !last && flashcard.audio_url;

  return (
    <>
      <h3 className="biz-udpmincho-regular">
        <span dangerouslySetInnerHTML={{ __html: formattedSentence(flashcard, flipped) }} />
        {flipped && (
        <><a style={{ fontSize: '18px' }} href="#" className="text-very-muted" onClick={copySentenceToClipboard}><i className="bi bi-copy"></i></a>{' '}</>
        )}
        {hasAudio && (!audioLoaded || audioPlaying) && (
        <div className="spinner-border spinner-border-sm" style={{ borderWidth: '1px', borderColor: '#ccc', borderRightColor: 'transparent' }} role="status"></div>
        )}
        {hasAudio && audioLoaded && !audioPlaying && (
        <a style={{ fontSize: '18px' }} href="#" className="text-very-muted" onClick={handleClickPlayAudio}><i className="bi bi-volume-up"></i></a>
        )}
      </h3>

      <p style={{ visibility: flipped ? 'visible' : 'hidden' }}>{flashcard.sentence_meaning}</p>

      <p style={{ visibility: flipped ? 'visible' : 'hidden' }}><Markdown>{formatBreakdown(flashcard.sentence_hiragana)}</Markdown></p>

      {!isCardNew && (
        <>
          <span className={`${(hideButtons ?? flipped) && 'invisible'}`}>
            <button type="button" className="btn btn-primary" onClick={onFlip}>Flip</button>
          </span>
          <span className={`${(hideButtons ?? !flipped) && 'invisible'}`}>
            <button type="button" className="btn btn-danger me-2" onClick={handleFail}>I didn&apos;t get it</button>
            <button type="button" className="btn btn-success me-2" onClick={handleHardSuccess}>I got it (difficult)</button>
            <button type="button" className="btn btn-success" onClick={handleEasySuccess}>I got it (easy)</button>
          </span>
        </>
      )}
      {isCardNew && !hideButtons && (
        <div className="text-center">
          <button type="button" className="btn btn-outline-secondary me-2" onClick={handleFail}>Next</button>
        </div>
      )}

      {last && (
        <div>
          Grade:{' '}
          <span style={{ border: flashcard.flashcard.mastery_level === 'learning' ? '1px solid #ccc' : 'none' }} className={`badge text-${flashcard.flashcard.mastery_level}`}>{flashcard.flashcard.mastery_level}</span>{' '}
          <span className="mb-0"><small className="text-very-muted">You will train this card again in {flashcard.flashcard.interval >= 24 ? pluralize('day', Math.round(flashcard.flashcard.interval / 24)) : pluralize('hour', flashcard.flashcard.interval)}.</small></span>
        </div>
      )}
    </>
  );
};

export default FlashcardCard;
