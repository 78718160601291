import React, { useEffect } from 'react'
import type { Stats, StatsLevel } from '../types';
import { formatLevel, colorClassFromLevel, isLevelUntrained, loadTooltips } from '../common';

interface Props {
  stats?: Stats
  userLevel?: number
  userSubscribed: boolean
}

const StatsPane: React.FC<Props> = ({ stats, userLevel, userSubscribed }) => {
  useEffect(loadTooltips, []);

  return (
    <>
      <h4>Stats</h4>
      <table className="table table-sm">
        <thead>
          <tr>
            <th colSpan={2}>Level</th>
            <th className="text-untrained">Untrained</th>
            <th className="text-learning">Learning</th>
            <th className="text-intermediate">Interm. <i className="bi bi-question-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Intermediate: Cards that are still easy after a space of 6+ days"></i></th>
            <th className="text-mastered">Mastered <i className="bi bi-question-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Mastered: Cards that are still easy after a space of 30+ days"></i></th>
          </tr>
        </thead>
        <tbody>
          {!!stats && stats.levels.map((statsLevel: StatsLevel) => {
            const levelLocked = statsLevel.level !== 10 && !userSubscribed;
            return <>
              <tr className={`${isLevelUntrained(statsLevel) || levelLocked ? 'text-very-muted' : ''}`}>
                <td rowSpan={2}>
                  <span className={`badge ${colorClassFromLevel(statsLevel.level)}`}>{formatLevel(statsLevel.level)}</span>
                  {statsLevel.level === userLevel && (
                    <>
                      <br/>
                      {'⛷️'}
                    </>
                  )}
                </td>
                <td className="fw-bold">Kanji</td>
                <td className="fw-bold">{levelLocked ? <i className="bi bi-lock"></i> : statsLevel.kanji.untrained}</td>
                <td className="fw-bold">{levelLocked ? <i className="bi bi-lock"></i> : statsLevel.kanji.learning}</td>
                <td className="fw-bold">{levelLocked ? <i className="bi bi-lock"></i> : statsLevel.kanji.intermediate}</td>
                <td className="fw-bold">{levelLocked ? <i className="bi bi-lock"></i> : statsLevel.kanji.mastered}</td>
              </tr>
              <tr className={`table-light ${isLevelUntrained(statsLevel) || levelLocked ? 'text-very-muted' : ''}`}>
                <td>Vocab</td>
                <td>{levelLocked ? <i className="bi bi-lock"></i> : statsLevel.vocab.untrained}</td>
                <td>{levelLocked ? <i className="bi bi-lock"></i> : statsLevel.vocab.learning}</td>
                <td>{levelLocked ? <i className="bi bi-lock"></i> : statsLevel.vocab.intermediate}</td>
                <td>{levelLocked ? <i className="bi bi-lock"></i> : statsLevel.vocab.mastered}</td>
              </tr>
            </>;
          })}
          {!!stats && (
            <>
              <tr>
                <td rowSpan={2}>All</td>
                <td className="fw-bold">Kanji</td>
                <td className="fw-bold">{stats.levels.reduce((acc: number, statsLevel: StatsLevel) => (acc + statsLevel.kanji.untrained), 0)}</td>
                <td className="fw-bold">{stats.levels.reduce((acc: number, statsLevel: StatsLevel) => (acc + statsLevel.kanji.learning), 0)}</td>
                <td className="fw-bold">{stats.levels.reduce((acc: number, statsLevel: StatsLevel) => (acc + statsLevel.kanji.intermediate), 0)}</td>
                <td className="fw-bold">{stats.levels.reduce((acc: number, statsLevel: StatsLevel) => (acc + statsLevel.kanji.mastered), 0)}</td>
              </tr>
              <tr className={'table-light'}>
                <td>Vocab</td>
                <td>{stats.levels.reduce((acc: number, statsLevel: StatsLevel) => (acc + statsLevel.vocab.untrained), 0)}</td>
                <td>{stats.levels.reduce((acc: number, statsLevel: StatsLevel) => (acc + statsLevel.vocab.learning), 0)}</td>
                <td>{stats.levels.reduce((acc: number, statsLevel: StatsLevel) => (acc + statsLevel.vocab.intermediate), 0)}</td>
                <td>{stats.levels.reduce((acc: number, statsLevel: StatsLevel) => (acc + statsLevel.vocab.mastered), 0)}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </>
  );
};

export default StatsPane;
