import React, { useEffect, useState, useRef } from 'react';
import type { UserProfile, UserSettings as UserSettingsType } from '../types';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import SubscribeButton from './SubscribeButton';
import { formatLevel } from '../common';

const UserSettings: React.FC = () => {
  const [profile, setProfile] = useState<UserProfile | undefined>(undefined);
  const [settings, setSettings] = useState<UserSettingsType | undefined>(undefined);
  const [loadingSubscriptionStatus, setLoadingSubscriptionStatus] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [searchParams] = useSearchParams();

  const toastRef = useRef<HTMLDivElement | null>(null);

  const userProfileQuery = useQuery({
    queryKey: ['user-profile'],
    queryFn: async () =>
      await axios
        .get('/api/users/profile')
        .then((res) => res.data),
  });

  useEffect(() => {
    if (userProfileQuery?.data) {
      setProfile(userProfileQuery.data as UserProfile);
      setSettings(userProfileQuery.data.settings as UserSettingsType);
    }
  }, [JSON.stringify(userProfileQuery?.data)]);

  useEffect(() => {
    if (searchParams.get('checkout_session_id')) {
      setLoadingSubscriptionStatus(true);
      void (async () => {
        const { data } = await axios.get(`/api/users/subscription_status?checkout_session_id=${searchParams.get('checkout_session_id')}`);
        if (data.status === 'complete') {
          await userProfileQuery.refetch();
          setLoadingSubscriptionStatus(false);
        } else {
          setLoadingSubscriptionStatus(false);
        }
      })();
    }
  }, [searchParams.get('checkout_session_id')]);

  const handleAutoplayChange = async (event: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    const value = event.target.value === 'true'
    setSettings((prev) => ({ ...prev, audio_autoplay: value }));
    void patchSettings({ audio_autoplay: value });
  };

  const handleKeyboardShortcutsChange = async (event: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    const value = event.target.value === 'true'
    setSettings((prev) => ({ ...prev, keyboard_shortcuts: value }));
    void patchSettings({ keyboard_shortcuts: value });
  };

  const patchSettings = async (settings: UserSettingsType): Promise<void> => {
    setIsUpdating(true);
    await axios.patch(
      '/api/users/settings',
      { settings },
      {
        headers: {
          'Content-Type': 'application/json', // Tells Rails it's a JSON request
          Accept: 'application/json', // Ensures Rails responds with JSON
        }
      }
    );
    await userProfileQuery.refetch();
    // @ts-expect-error bootstrap is already loaded
    const toast = new bootstrap.Toast(toastRef.current);
    if (toast) {
      toast.show();
    }
    setIsUpdating(false);
  };

  if (!profile) {
    return 'Loading..'
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          {searchParams.get('checkout_session_id') && userProfileQuery?.data?.subscription_status === 'active' && (
            <div className="alert alert-success" role="alert">
              You&apos;re subscribed!
            </div>
          )}

          <h1>Settings</h1>
          <p>
            <strong>Name</strong><br/>
            {profile.name}
          </p>
          <p>
            <strong>Email</strong><br/>
            {profile.email}
          </p>
          <p>
            <strong>Level</strong><br/>
            {formatLevel(profile.level)}
          </p>
          <p>
            <strong>Subscription Status</strong><br/>
            {!loadingSubscriptionStatus && profile.subscription_status === 'active' && <><i className="text-success bi bi-check-circle-fill"></i>{' '}</>}
            {loadingSubscriptionStatus ? 'Loading...' : profile.subscription_status}
          </p>
          {profile.subscription_status !== 'active' && (
            <p>
              <SubscribeButton disabled={loadingSubscriptionStatus} large={true} />
            </p>
          )}
          <p>
            <strong>Autoplay flashcard audio</strong><br/>
            <select className="form-select" value={settings?.audio_autoplay ? 'true' : 'false'} disabled={isUpdating} onChange={(event) => { void handleAutoplayChange(event) }}>
              <option value="false">Disabled</option>
              <option value="true">Enabled</option>
            </select>
          </p>
          <p>
            <strong>Keyboard shortcuts</strong><br/>
            <select className="form-select" value={settings?.keyboard_shortcuts ? 'true' : 'false'} disabled={isUpdating} onChange={(event) => { void handleKeyboardShortcutsChange(event) }}>
              <option value="false">Disabled</option>
              <option value="true">Enabled</option>
            </select>
            <table>
              <tbody>
                <tr>
                  <td><span className="badge bg-light text-dark">Space</span></td>
                  <td>Next card (Lessons) / Flip card / Play audio</td>
                </tr>
                <tr>
                  <td><span className="badge bg-light text-dark">1</span></td>
                  <td>&quot;I didn&apos;t get it&quot;</td>
                </tr>
                <tr>
                  <td><span className="badge bg-light text-dark">2</span></td>
                  <td>&quot;I got it (difficult)&quot;</td>
                </tr>
                <tr>
                  <td><span className="badge bg-light text-dark">3</span></td>
                  <td>&quot;I got it (easy)&quot;</td>
                </tr>
              </tbody>
            </table>
          </p>
        </div>
      </div>

      <div className="toast-container position-absolute p-3 bottom-0 end-0">
        <div ref={toastRef} className="toast align-items-center text-white bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
          <div className="d-flex">
            <div className="toast-body">
            Settings updated.
            </div>
            <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
