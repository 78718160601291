import React, { useEffect, useState } from 'react';
import axios from 'axios';
import type { AdminKanji } from '../../types';
import KanjiRow from './KanjiRow';

const KanjiIndex: React.FC = () => {
  /* Hooks */

  const [kanji, setKanji] = useState<AdminKanji[]>([]);
  const [level, setLevel] = useState<string>('10');

  useEffect(() => {
    void (async () => {
      const res = await axios.get(`/api/admin/kanji?level=${level}`);
      setKanji(res.data.kanji as AdminKanji[]);
    })();
  }, [level]);

  /* Handlers */

  const handleLevelChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setLevel(event.target.value);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2">
          <select className="form-select" value={level} onChange={handleLevelChange}>
            <option value="10">10級</option>
            <option value="9">9級</option>
            <option value="8">8級</option>
            <option value="7">7級</option>
            <option value="6">6級</option>
            <option value="5">5級</option>
            <option value="4">4級</option>
            <option value="3">3級</option>
            <option value="2">2級</option>
          </select>
        </div>
      </div>

      <br/>
      <p>Showing {kanji.length} kanji</p>

      <table className="table">
        <thead>
          <tr>
            <th>Level</th>
            <th>Character</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {kanji.map((_kanji: AdminKanji) =>
            <KanjiRow key={_kanji.id} preloadedKanji={_kanji} />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default KanjiIndex;
