import React, { useEffect, useState, useRef } from 'react';
import type { Flashcard, Stats, UserProfile } from '../types';
import { getFlashcard, postFlashcard, getStats, postUserTimezone } from '../actions';
import KanjiGrid from './KanjiGrid';
import StatsPane from './StatsPane';
import FlashcardPane from './FlashcardPane';
import axios from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const Home: React.FC = () => {
  const [flashcard, setFlashcard] = useState<Flashcard | undefined | null>(undefined);
  const [lastFlashcard, setLastFlashcard] = useState<Flashcard | undefined>(undefined);
  const [flipped, setFlipped] = useState<boolean>(false);
  const [stats, setStats] = useState<Stats | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [started, setStarted] = useState<boolean>(false);
  const [cardCounter, setCardCounter] = useState<number>(0); // initial index 1
  const [userLevel, setUserLevel] = useState<number | undefined>(undefined);
  const [userSubscribed, setUserSubscribed] = useState<boolean>(false);
  const [justFinishedNewBatch, setJustFinishedNewBatch] = useState<boolean>(false);
  const [userNextReviewInMinutes, setUserNextReviewInMinutes] = useState<number>(0);
  const [reviewCount, setReviewCount] = useState<number>(0);
  const [freezeReviewCount, setFreezeReviewCount] = useState<number>(0);
  const [audioLoaded, setAudioLoaded] = useState<boolean>(false);
  const [audioPlaying, setAudioPlaying] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const userProfileQuery = useQuery({
    queryKey: ['user-profile'],
    queryFn: async () =>
      await axios
        .get('/api/users/profile')
        .then((res) => res.data as UserProfile),
  });

  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (userProfileQuery?.data?.level) {
      const level: number = userProfileQuery.data.level;
      setUserLevel(level)
    }
    if (userProfileQuery?.data?.subscription_status) {
      setUserSubscribed(userProfileQuery.data.subscription_status === 'active');
    }
  }, [userProfileQuery?.data?.level, userProfileQuery?.data?.subscription_status]);

  useEffect(() => {
    void (async () => {
      const stats = await getStats();
      setStats(stats);
    })()
  }, [cardCounter, lastFlashcard?.flashcard_id]);

  useEffect(() => {
    void (async () => {
      const {
        flashcard: _flashcard,
        user_next_review_in_minutes: _userNextReviewInMinutes,
        review_count: _reviewCount,
      } = await getFlashcard();
      setFlashcard(_flashcard);
      setUserNextReviewInMinutes(_userNextReviewInMinutes);
      setReviewCount(_reviewCount);

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      void postUserTimezone(timezone);
    })()
  }, []);

  useEffect(() => {
    const audio = audioRef.current;

    setAudioLoaded(!!audio);

    if (flipped && userProfileQuery?.data?.settings?.audio_autoplay) {
      playAudio();
    }

    const handleAudioEnd = (): void => {
      setAudioPlaying(false);
    };

    // Add event listener for the 'ended' event
    if (audio) {
      audio.addEventListener('ended', handleAudioEnd);
    }

    // Cleanup the event listener when the component unmounts
    return () => {
      if (audio) {
        audio.removeEventListener('ended', handleAudioEnd);
      }
    };
  }, [audioRef.current?.src, flipped, userProfileQuery?.data?.settings?.audio_autoplay]);

  // Use useEffect to add the event listener
  useEffect(() => {
    if (userProfileQuery?.data?.settings?.keyboard_shortcuts) {
      window.addEventListener('keydown', handleKeyDown);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [userProfileQuery?.data?.settings?.keyboard_shortcuts, flashcard?.flashcard?.attempts, started, flipped]);

  // Function to handle the key press event
  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === ' ') {
      const newCard = flashcard?.flashcard?.attempts === 0;
      if (started && newCard) {
        event.preventDefault();
        handleNext(0);
      } else if (started && !flipped) {
        event.preventDefault();
        setFlipped(true);
      } else if (started && flipped) {
        event.preventDefault();
        playAudio();
      }
      return;
    }

    if (started && flipped) {
      if (event.key === '1') {
        event.preventDefault();
        handleNext(0);
      } else if (event.key === '2') {
        event.preventDefault();
        handleNext(3);
      } else if (event.key === '3') {
        event.preventDefault();
        handleNext(5);
      }
    }
  };

  const playAudio = (): void => {
    if (audioRef.current) {
      setAudioPlaying(true);
      void audioRef.current.play();
    }
  };

  const handleFlip = (): void => {
    setFlipped(true);
  };

  const startReview = (): void => {
    setStarted(true);
    setJustFinishedNewBatch(false);
    setFreezeReviewCount(reviewCount);
    setCardCounter(1);
  };

  const startNewBatch = (): void => {
    setCardCounter(1);
    setStarted(true);
  };

  const feedback = async (grade: number): Promise<void> => {
    if (!flashcard) {
      return;
    }
    setLoading(true);

    // check if user is in the middle of a new batch
    const isDoingNewBatch = flashcard.flashcard.attempts === 0 && cardCounter < 10;
    const _justFinishNewBatch = flashcard.flashcard.attempts === 0 && !isDoingNewBatch;
    // new batch finished
    if (_justFinishNewBatch) {
      setJustFinishedNewBatch(true);
      setStarted(false);
      setLastFlashcard(undefined);
    }

    // fetch next card (review or new)
    const {
      flashcard: _flashcard,
      new_flashcard: newFlashcard,
      new_level: newLevel,
      user_next_review_in_minutes: _userNextReviewInMinutes,
      review_count: _reviewCount,
    } = await postFlashcard(flashcard.flashcard_id, grade, isDoingNewBatch);

    setUserNextReviewInMinutes(_userNextReviewInMinutes);
    setReviewCount(_reviewCount);
    setFreezeReviewCount(_reviewCount + cardCounter);

    // check if the card just finished was the last available review card
    const wasLastReviewCard = _flashcard.flashcard.attempts > 1 && (newFlashcard?.flashcard?.attempts === 0 || newFlashcard === null);
    if (wasLastReviewCard) {
      setStarted(false);
      setLastFlashcard(undefined);
    }

    // setup new card for review
    setFlipped(false);
    if (!_justFinishNewBatch) {
      setLastFlashcard(_flashcard);
    }
    setCardCounter((prev) => prev + 1);
    setFlashcard(newFlashcard);

    // trigger level change if the user leveled up
    if (newLevel) {
      void queryClient.invalidateQueries({ queryKey: ['user-profile'] });
    }

    setLoading(false);
  };

  const handleNext = (grade: number): void => {
    void feedback(grade);
  };

  const statsPane = <StatsPane stats={stats} userLevel={userLevel} userSubscribed={userSubscribed} />

  if (userProfileQuery.error) {
    return 'Error loading user';
  }

  if (userProfileQuery.isLoading || !userProfileQuery.data) {
    return 'Loading...';
  }

  const isCardNew = flashcard?.flashcard?.attempts === 0;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-4 col-md-12 d-lg-block d-none">
          {statsPane}
        </div>

        <div className="col-lg-4 col-md-12">
          <FlashcardPane
            flashcard={flashcard}
            cardCounter={cardCounter}
            lastFlashcard={lastFlashcard}
            started={started}
            flipped={flipped}
            isCardNew={isCardNew}
            loading={loading}
            onStartReview={startReview}
            onStartNewBatch={startNewBatch}
            onFlip={handleFlip}
            onNext={handleNext}
            justFinishedNewBatch={justFinishedNewBatch}
            userNextReviewInMinutes={userNextReviewInMinutes}
            freezeReviewCount={freezeReviewCount}
            reviewCount={reviewCount}
            userSubscribed={userSubscribed}
            audioLoaded={audioLoaded}
            audioPlaying={audioPlaying}
            playAudio={playAudio}
          />
          {!!flashcard?.audio_url && (
            <audio ref={audioRef} src={flashcard.audio_url} />
          )}
        </div>

        <div className="col-lg-4 col-md-12">
          <KanjiGrid
            flashcard={started ? flashcard : undefined}
            lastFlashcard={lastFlashcard}
            flipped={flipped || isCardNew}
            userLevel={userLevel ?? 10}
            userSubscribed={userSubscribed}
            stats={stats}
          />
        </div>

        <div className="col-lg-4 col-md-12 mt-4 d-lg-none">
          {statsPane}
        </div>
      </div>
    </div>
  );
};

export default Home;
