import React, { useEffect, useState } from 'react';
import type { AdminKanji } from '../../types';
import { Link } from 'react-router-dom';

interface Props {
  preloadedKanji: AdminKanji
}

const KanjiRow: React.FC<Props> = ({ preloadedKanji }) => {
  /* Hooks */

  const [kanji, setKanji] = useState<AdminKanji>(preloadedKanji);

  useEffect(() => {
    setKanji(preloadedKanji);
  }, [preloadedKanji.id]);

  /* Handlers */

  return (
    <tr>
      <td>{kanji.level}</td>
      <td>{kanji.character}</td>
      <td><Link to={`/admin/kanji/${kanji.id}`} className="btn btn-outline-secondary btn-sm" target="_blank">View</Link></td>
    </tr>
  );
};

export default KanjiRow;
