import React, { useEffect, useState } from 'react';
import type { MouseEvent } from 'react';
import type { Flashcard, Kanji, KanjiMastery, Stats } from '../types';
import { colorClassFromLevel, kanjiString, formatLevel } from '../common';
import { getAllKanjiMastery } from '../actions';
import axios from 'axios';
import KanjiDefinition from './KanjiDefinition';

interface Props {
  stats?: Stats
  flashcard?: Flashcard | null
  lastFlashcard?: Flashcard
  flipped: boolean
  userLevel: number
  userSubscribed: boolean
}

const KanjiGrid: React.FC<Props> = ({ flashcard, lastFlashcard, flipped, userLevel, stats, userSubscribed }) => {
  const [activeTab, setActiveTab] = useState<number>(10);
  const [kanjiMastery, setKanjiMastery] = useState<KanjiMastery | undefined>(undefined)
  const [kanji, setKanji] = useState<Kanji | undefined>(undefined)
  const [isKanjiBrowsing, setIsKanjiBrowsing] = useState<boolean>(false);

  useEffect(() => {
    void (async () => {
      setKanjiMastery(await getAllKanjiMastery());
    })();
  }, [lastFlashcard?.flashcard_id, lastFlashcard?.flashcard?.mastery_level]);

  useEffect(() => {
    if (flashcard?.flashcard_id) {
      void showFlashcardKanji();
    }
  }, [flashcard?.flashcard_id, flipped]);

  const showFlashcardKanji = async (): Promise<void> => {
    if (flashcard?.level) {
      setActiveTab(flashcard.level);
    }
    if (flashcard?.kanji_id) {
      const res = await axios.get(`/api/kanji/${flashcard.kanji_id}`)
      setKanji(res.data as Kanji);
      setIsKanjiBrowsing(false);
    }
  };

  const handleLevelTabClick = (event: MouseEvent<HTMLAnchorElement>, level: number): void => {
    event.preventDefault();
    setActiveTab(level);
  };

  const handleKanjiClick = (event: MouseEvent<HTMLAnchorElement>, kanji: string): void => {
    event.preventDefault();
    void (async () => {
      const res = await axios.get(`/api/kanji/${kanji}?character=true`);
      setKanji(res.data as Kanji);
      setIsKanjiBrowsing(true);
    })();
  };

  const reviewForecast = stats?.review_forecast ?? {};

  return (
    <>
      <h4 className="mt-4 mt-lg-0">Review Forecast</h4>
      <table className="table table-sm">
        <thead>
          <tr>
            <th>Today</th>
            <th>Tomorrow</th>
            <th>+2 days</th>
            <th>+3 days</th>
            <th>+4 days</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{reviewForecast[0] || 0}</td>
            <td>{reviewForecast[1] || 0}</td>
            <td>{reviewForecast[2] || 0}</td>
            <td>{reviewForecast[3] || 0}</td>
            <td>{reviewForecast[4] || 0}</td>
          </tr>
        </tbody>
      </table>

      <h4 className="mt-4">Kanji Grid</h4>
      <ul className="nav justify-content-center mb-1">
        {[10, 9, 8, 7, 6, 5, 4, 3, 2].map((level: number) =>
          <li key={level} className="nav-item">
            {level === 10 || userSubscribed
              ? <a className={`nav-link ${activeTab === level ? colorClassFromLevel(level) : ''}`} href="#" onClick={(event: MouseEvent<HTMLAnchorElement>) => { handleLevelTabClick(event, level) }}>{formatLevel(level)}</a>
              : <a className="nav-link px-2">{formatLevel(level)}<i className="bi bi-lock"></i></a>
            }
          </li>
        )}
      </ul>

      {!!kanjiMastery && (
        <p className={`${activeTab < userLevel ? 'text-very-muted' : ''}`}>
          {kanjiString(activeTab).split('').map((kanji: string) => userSubscribed || activeTab === 10 ? <a href="#" key={kanji} className={`link-unstyled ${kanji === flashcard?.kanji ? 'word-highlight' : ''} ${kanjiMastery ? `text-${kanjiMastery[activeTab][kanji]}` : ''}`} onClick={(event) => { handleKanjiClick(event, kanji) }}>{kanji}</a> : <></>)}
        </p>
      )}

      {!!kanji && <KanjiDefinition kanji={kanji} flipped={isKanjiBrowsing || flipped} currentReading={flashcard?.reading} />}
    </>
  );
};

export default KanjiGrid;
