import React, { useEffect, useState } from 'react';
import axios from 'axios';
import type { AdminReading } from '../../types';
import ReadingsRow from './ReadingsRow';

const ReadingsIndex: React.FC = () => {
  /* Hooks */

  const [readings, setReadings] = useState<AdminReading[]>([]);
  const [filteredReadings, setFilteredReadings] = useState<AdminReading[]>([]);
  const [level, setLevel] = useState<string>('10');
  const [isNoSentenceOnly, setIsNoSentenceOnly] = useState<boolean>(false);
  const [numNoSentence, setNumNoSentence] = useState<number>(0);
  const [isNoImageOnly, setIsNoImageOnly] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');

  useEffect(() => {
    void (async () => {
      const res = await axios.get(`/api/admin/readings?level=${level}`);
      setReadings(res.data.readings as AdminReading[]);
    })();
  }, [level]);

  useEffect(() => {
    setFilteredReadings(getFilteredReadings());
    setNumNoSentence(readings.filter((reading: AdminReading) => !reading.sentence).length);
  }, [JSON.stringify(readings)]);

  useEffect(() => {
    setFilteredReadings(getFilteredReadings())
  }, [isNoSentenceOnly, isNoImageOnly, searchString]);

  /* Handlers */

  const getFilteredReadings = (): AdminReading[] => {
    let _filteredReadings = readings;
    if (searchString) {
      _filteredReadings = _filteredReadings.filter((reading: AdminReading) => reading.kanji.includes(searchString) || reading.reading.includes(searchString));
    }
    if (isNoSentenceOnly) {
      _filteredReadings = _filteredReadings.filter((reading: AdminReading) => !reading.sentence);
    }
    if (isNoImageOnly) {
      _filteredReadings = _filteredReadings.filter((reading: AdminReading) => !reading.image_url);
    }
    return _filteredReadings;
  };

  const handleLevelChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setLevel(event.target.value);
  };

  const handleSearchStringChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchString(event.target.value);
  };

  const handleNoSentenceOnlyChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIsNoSentenceOnly(event.target.checked);
  };

  const handleNoImageOnlyChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIsNoImageOnly(event.target.checked);
  };

  const handleReadingUpdate = (reading: AdminReading): void => {
    const _readings = [...readings];
    const index = _readings.findIndex((_reading: AdminReading) => _reading.id === reading.id);
    _readings[index] = reading;
    setReadings(_readings);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2">
          <select className="form-select" value={level} onChange={handleLevelChange}>
            <option value="10">10級</option>
            <option value="9">9級</option>
            <option value="8">8級</option>
            <option value="7">7級</option>
            <option value="6">6級</option>
            <option value="5">5級</option>
            <option value="4">4級</option>
            <option value="3">3級</option>
            <option value="2">2級</option>
          </select>
        </div>

        <div className="col-md-2">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="noSentenceOnly"
              checked={isNoSentenceOnly}
              onChange={handleNoSentenceOnlyChange}
            />
            <label className="form-check-label" htmlFor="noSentenceOnly">
              no-sentence only
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="noVocabMeaningOnly"
              checked={isNoImageOnly}
              onChange={handleNoImageOnlyChange}
            />
            <label className="form-check-label" htmlFor="noVocabMeaningOnly">
              no image only
            </label>
          </div>
        </div>

        <div className="col-md-3">
          <input type="text" className="form-control" id="searchString" value={searchString} onChange={handleSearchStringChange} placeholder="search" />
        </div>

        <div className="col-md-3">
        </div>

        <div className="col-md-2">
          <strong className={numNoSentence ? 'text-danger' : 'text-success'}>{readings.length - numNoSentence}</strong>/{readings.length} have sentences
        </div>
      </div>

      <br/>
      <p>Showing {filteredReadings.length} readings</p>

      <table className="table">
        <thead>
          <tr>
            <th>Level</th>
            <th>Grade</th>
            <th>Kanji</th>
            <th>Reading</th>
            <th>Vocab</th>
            <th>Sentence</th>
            <th>Image Tmp</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredReadings.map((reading: AdminReading) =>
            <ReadingsRow key={reading.id} preloadedReading={reading} onUpdate={handleReadingUpdate} />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ReadingsIndex;
