import React, { useEffect, useState } from 'react';
import type { AdminReading } from '../../types';
import { Link } from 'react-router-dom';

interface Props {
  preloadedReading: AdminReading
  onUpdate: (reading: AdminReading) => void
}

const ReadingsRow: React.FC<Props> = ({ preloadedReading, onUpdate }) => {
  /* Hooks */

  const [reading, setReading] = useState<AdminReading>(preloadedReading);

  useEffect(() => {
    setReading(preloadedReading);
  }, [preloadedReading.id]);

  /* Handlers */

  return (
    <tr>
      <td>{reading.level}</td>
      <td>{reading.grade}</td>
      <td>
        <Link to={`/admin/kanji/${reading.kanji_id}`} target="_blank" className="link-unstyled">{reading.kanji}</Link>
      </td>
      <td style={{ whiteSpace: 'nowrap' }}>
        {reading.reading}
      </td>
      <td>
        {reading.vocab} ({reading.vocab_hiragana}): {reading.vocab_meaning}
      </td>
      <td width="20%">
        {reading.sentence}<br/>
        {reading.sentence_meaning}
      </td>
      <td>
        {reading.image_tmp && (
          <img width="100%" src={reading.image_tmp} />
        )}
      </td>
      <td>
        {reading.image_url && (
          <img width="100%" src={reading.image_url} />
        )}
      </td>
      <td>
        <Link to={`/admin/readings/${reading.id}`} className="btn btn-outline-secondary btn-sm" target="_blank">View</Link>
      </td>
    </tr>
  );
};

export default ReadingsRow;
