import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import type { AdminKanji } from '../../types';
import KanjiDefinition from '../KanjiDefinition';

const KanjiDetails: React.FC = () => {
  /* Hooks */

  const { id: kanjiId } = useParams();

  const kanjiQuery = useQuery({
    queryKey: ['admin', 'kanji', kanjiId],
    queryFn: async () =>
      await axios
        .get(`/api/admin/kanji/${kanjiId}`)
        .then((res) => res.data),
  });

  /* Handlers */

  if (kanjiQuery.isLoading) return 'Loading...';

  const kanji = kanjiQuery.data as AdminKanji;

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <table className="table table-borderless">
            <tbody>
              <tr>
                <th>Level</th>
                <td>{kanji.level}</td>
              </tr>
              <tr>
                <th>Character</th>
                <td>{kanji.character}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="col-md-6">
          <h3>Kanji Definition preview</h3>
          <KanjiDefinition kanji={kanji} flipped={false} />
          <KanjiDefinition kanji={kanji} flipped={true} />
        </div>
      </div>
    </div>
  );
};

export default KanjiDetails;
