import React, { useEffect, useState } from 'react';
import axios from 'axios';
import type { User } from '../../types';
import { useParams } from 'react-router-dom';

const UserDetails: React.FC = () => {
  /* Hooks */

  const { id: userId } = useParams();
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    void (async () => {
      const res = await axios.get(`/api/admin/users/${userId}`);
      setUser(res.data as User);
    })();
  }, []);

  /* end hooks */

  if (!user) {
    return 'Loading...';
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h3>{user.name}</h3>
          <p>{user.email}</p>
        </div>

        <div className="col-md-6">
          <table className="table">
            <thead>
              <tr>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>ID</th>
                <td>{user.id}</td>
              </tr>
              <tr>
                <th>Created Date</th>
                <td>{user.created_at.toString()}</td>
              </tr>
              <tr>
                <th>Provider</th>
                <td>{user.provider}</td>
              </tr>
              <tr>
                <th>Timezone</th>
                <td>{user.timezone}</td>
              </tr>
              <tr>
                <th>Level</th>
                <td>{user.level}</td>
              </tr>
              <tr>
                <th>Flashcard Count</th>
                <td>{user.flashcard_count}</td>
              </tr>
              <tr>
                <th>Subscription Status</th>
                <td>{user.subscription_status}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="col-md-6">
        </div>
      </div>

    </div>
  );
};

export default UserDetails;
