import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import AdminApp from './components/admin/AdminApp';

document.addEventListener('DOMContentLoaded', () => {
  const userRoot = document.getElementById('userRoot');
  const adminRoot = document.getElementById('adminRoot');
  if (userRoot) {
    const userRootContainer = ReactDOM.createRoot(userRoot);
    userRootContainer.render(<App />);
  } else if (adminRoot) {
    const adminRootContainer = ReactDOM.createRoot(adminRoot);
    adminRootContainer.render(<AdminApp />);
  }
});
