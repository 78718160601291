import React, { useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { formatLevel, colorClassFromLevel, loadTooltips } from '../common';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import SubscribeButton from './SubscribeButton';

const Layout: React.FC = () => {
  const userQuery = useQuery({
    queryKey: ['user-profile'],
    queryFn: async () =>
      await axios
        .get('/api/users/profile')
        .then((res) => res.data),
  });

  const userLevel: undefined | number = userQuery?.data?.level;

  useEffect(loadTooltips, [userQuery?.data?.level, userQuery?.data?.subscription_status]);

  const button = userLevel && userQuery?.data?.subscription_status === 'active'
    ? (
      <a href="#" className={`btn ${colorClassFromLevel(userLevel ?? 10)}`} aria-current="page" data-bs-toggle="tooltip" data-bs-trigger="focus" data-bs-placement="bottom" title={userLevel && userLevel > 2 ? `Get all Level ${formatLevel(userLevel)} cards to Intermediate to reach Level ${formatLevel(userLevel - 1)}!` : ''}>{userLevel ? `Level ${formatLevel(userLevel)}` : 'loading...'}</a>
      )
    : userQuery?.data?.subscription_status === 'inactive'
      ? <SubscribeButton />
      : <></>;

  return (
    <div>
      <div className="container-fluid">
        <header className="d-flex flex-wrap justify-content-center py-3">
          <a href="/" className="d-flex align-items-center mb-md-0 text-dark text-decoration-none">
            <span style={{ fontSize: '28px' }}>⛷️</span>
          </a>

          <ul className="navbar-nav mx-auto">
            <li className="nav-item">{button}</li>
          </ul>

          <ul className="nav nav-pills">
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle text-dark" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="bi bi-person-circle"></i>
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link to="/settings" className="dropdown-item">Settings</Link></li>
                <li><Link to="mailto:help@kanji.ski" className="dropdown-item">Help</Link></li>
                <li><a className="dropdown-item" href="/users/sign_out">Logout</a></li>
              </ul>
            </li>
          </ul>
        </header>
      </div>

      <Outlet />
    </div>
  );
};

export default Layout;
